import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {mess1List, mess2List, APICALL_URL, IMGS_URL } from '../hooks/data'
import { Check } from 'react-bootstrap-icons'
import { Button, Modal } from 'react-bootstrap'
import { XLg, CheckLg, Trash, Save2 } from "react-bootstrap-icons"
import { db } from '../hooks/db'

function Caisses({page, action, actionChange, getSelection, initSelection, menuAction, setMenuAction, filtres, ordre, isOnline}){
    
    const userLocal = JSON.parse(localStorage.getItem("user"))
    
    const [refs, setRefs] = useState([])
    const [refsAff, setRefsAff] = useState([])
    const [idsCaisses, setIdsCaisses] = useState([])
    const [vignettes, setVignettes] = useState([])
    const [couvs, setCouvs] = useState([])
    const [success, setSuccess] = useState(false)
    const [randomInt, setRandomInt] = useState()
    const [selectionRefs, setSelectionRefs] = useState([])
    const [clickSelect, setClickSelect] = useState(0)
    const [selectAll, setSelectAll] = useState(false)
    const [nbRefAff, setNbRefAff] = useState(24)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [nbPages, setNbPages] = useState(1)
    const [contHeight, setContHeight] = useState()
    const [height, setHeight] = useState(0)
    const [prevHeight, setPrevHeight] = useState(0)
    const [listeLieux, setListeLieux] =useState([])
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    useEffect(() => {
        setContHeight(document.getElementById("content").clientHeight)
        const handleResize = () => {
            setContHeight(document.getElementById("content").clientHeight)
        }
        window.addEventListener('resize', handleResize)
        db.lieux.where('id').above(0).sortBy("id").then(
            res=>{
                let tempArray=[]
                res.map(car=>{
                tempArray[car.id]=car
                })
                setListeLieux(tempArray)
                console.log(tempArray)
        })
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const position = document.getElementById("content").scrollTop;
            setScrollPosition(position)
            setHeight(document.getElementById("zoneAff").clientHeight)
            if((position>(height-contHeight)) && height!==prevHeight){
                let nbPagesTemp=nbPages
                nbPagesTemp++
                //console.log("next page - - next page - - next page - - next page - - ")
                setRefsAff(refs.slice(0,nbRefAff*nbPagesTemp))
                setNbPages(nbPagesTemp)
                setPrevHeight(height)
            }
        }
        document.getElementById("content").addEventListener('scroll', handleScroll, { passive: true })
    return () => {
        //document.getElementById("content").removeEventListener('scroll', handleScroll)
    }
    }, [scrollPosition])

    useEffect(() => {
        if(initSelection) {
            setSelectionRefs([])
        }
    }, [initSelection])

    useEffect(() => {
        if(selectAll){
            let listeRefsSelect=[]
            refsAff.map(ref=>{
                listeRefsSelect.push(ref.id)
            })
            setSelectionRefs(listeRefsSelect)
        } else {
            setSelectionRefs([])
        }
    }, [selectAll])

    useEffect(() => {
        if(action==="select-true"){
            let listeRefsSelect=selectionRefs
            var index = listeRefsSelect.indexOf(clickSelect);
            if (index !== -1) {
                listeRefsSelect.splice(index, 1);
            } else if(clickSelect!==0) {
                listeRefsSelect.push(clickSelect)
            }
            setSelectionRefs(listeRefsSelect)
            console.log(listeRefsSelect)
            setClickSelect(0)
            getSelection(listeRefsSelect)
        } else if(clickSelect!==0) {
            //
        }
    }, [clickSelect])

    useEffect(() => {
        //console.log(selectionRefs)
        if(menuAction==="corbeille" || menuAction==="outCorbeille" || menuAction==="deleteRef"){
            let act=""
            if(menuAction==="corbeille") act="refsToCorbeille"
            if(menuAction==="outCorbeille") act="refsOutCorbeille"
            if(menuAction==="deleteRef") act="deleteRef"
            const postData = {
                action:act,
                references:selectionRefs,
                filtres:filtres,
                ordre:ordre,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
              }
            axios.post(APICALL_URL,postData,
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              })
              .then(res => {
                //console.log(res)
                setRefs(res.data.liste)
                setRefsAff(res.data.liste.slice(0,nbRefAff*nbPages))
                setVignettes(res.data.vignettes)
                setCouvs(res.data.couvs)
                setSuccess(true)
                setMenuAction("")
              })
        }
        if(menuAction==="deleteCaisse"){
            setShowConfirmDelete(true)
        }
    },[menuAction])

    useEffect(() => {
        setSelectionRefs([])
        document.getElementById("content").scrollTop=0
        if(filtres && ordre) {
            loadRefs()
        }
    },[filtres,ordre,isOnline])

    function toggleSelectAll(){
        setSelectAll(!selectAll)
    }

    function loadRefs(){
       if(isOnline) {
            setSuccess(false)
            const postData = {
                action:"getCaisses",
                filtres:filtres,
                ordre:ordre,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
            }
            console.log(postData)
            axios.post(APICALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                console.log(res)
                setRefs(res.data.liste)
                setIdsCaisses(res.data.listeIdsCaisses)
                setRefsAff(res.data.liste.slice(0,nbRefAff*nbPages))
                setVignettes(res.data.vignettes)
                setCouvs(res.data.couvs)
                setRandomInt(Math.floor(Math.random() * 100000))
                setSuccess(true)
            })
        }
    }
    function supItemsConfirm(){
        const postData = {
            action:"deleteCaisses",
            references:selectionRefs,
            filtres:filtres,
            ordre:ordre,
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
          console.log(postData)
        axios.post(APICALL_URL,postData,
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          })
          .then(res => {
            loadRefs()
            closeModalConfirm()
          })
    }
    function FondCaisse({idCaisse,objets}) {
        let vigns = []
        for (let i = 0; i<9; i++){
            let square=""
            if(objets[i]) {
                let objId=objets[i].id
                let sqBgd=""
                if(vignettes[objId]?.nom){
                    let vign=vignettes[objId]?.nom
                    let ext=vignettes[objId]?.extension
                    sqBgd="url("+IMGS_URL+objId+"/sm/"+vign+"."+ext+"?"+randomInt+")"
                } else {
                    sqBgd="none"
                }
                square=<div key={i} style={{backgroundImage:sqBgd}} className="sq-4"></div>
            } else {
                square=<div key={i} className="sq-4"></div>
            }
            vigns.push(square)
        }
        return vigns
        }

        function closeModalConfirm(){
            setShowConfirmDelete(false)
            setMenuAction("")
        }

        const modalConfirmDelete = <Modal show={showConfirmDelete} onHide={closeModalConfirm} centered>
        <Modal.Header closeButton>
        <Modal.Title>Confirmez la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer les caisses sélectionnées ?</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={closeModalConfirm}>
        <XLg /> Annuler
        </Button>
        <Button variant="primary" onClick={supItemsConfirm}>
            <CheckLg/> Oui
        </Button>
        </Modal.Footer>
    </Modal>
   
    return (
    <div className='container' id='zoneAff'>
        {modalConfirmDelete}
        {isOnline ?
        <div className='row gx-2 gx-md-3'>
        {refsAff.map(ref=>
            <div onClick={()=>setClickSelect(ref.id)} key={ref.id} className='col-4 col-md-3 mb-2 mb-md-4'>
                <div className='bloc-caisse'>
                    <div className="caisse-cont">
                        <FondCaisse idCaisse={ref.id} objets={ref.objets}/>
                    </div>
                    <div className='bloc-ref-visu'>
                        <div className="infosRef">
                            <span className="ref-show">C{ref.id}</span>
                            <span className="loc-show">
                                <>
                                {ref.detail.lieu!=0 && listeLieux[ref.detail.lieu].nom.substring(0,1)} | {ref.detail.zone} | {ref.detail.rangee} | {ref.detail.niveau}
                                </>
                            </span>
                        </div>
                    </div>
                    <div
                        className=
                        {(action==="select-true" && selectionRefs.includes(ref.id)) ? "selectZone" : "selectZone d-none"}
                        ></div>
                        {action!=="select-true" &&
                        <Link className='bloc-seul' to={"/caisses/"+ref.id}></Link>
                         }
                </div>
            </div>
        )}
        </div>
        :
        <div>Vous devez être connecté à internet</div>
        }
    </div>
    )
}

export default Caisses