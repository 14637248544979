import { Link } from 'react-router-dom'
import ROLES from '../../hooks/roles';

function Menu1({page,isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const role=userLocal.roles
    var classReferences="picto_menu references"
    var classCaisses="picto_menu caisses"
    var classArchives="picto_menu archives"
    var classParametres="picto_menu parametres"
    var classCompte="picto_menu compte"
    const ssmenuRef=["references","references-validees","reference-new","synchro"]
    const ssmenuPar=["parametres","users","lieux"]
    if(ssmenuRef.indexOf(page) > -1) classReferences+=" active";
    if(page==="caisses") classCaisses+=" active";
    if(page==="archives") classArchives+=" active";
    if(ssmenuPar.indexOf(page) > -1) classParametres+=" active";
    if(page==="compte") classCompte+=" active";
    if(page==="reference-edit" || page==="reference-new") {
        classReferences+=" disabled"
        classCaisses+=" disabled"
        classArchives+=" disabled"
        classParametres+=" disabled"
        classCompte+=" disabled"
    }
    return(
        <div className='container menu1'>
        { role>ROLES.Invite &&  <div className={classReferences}>{(page!=="reference-edit" && page!=="reference-new") &&<Link to='/references' key='references'></Link>}</div> }
        { role===ROLES.RegOne && <div className={classCaisses}>{(page!=="reference-edit" && page!=="reference-new") &&<Link to='/caisses' key='caisses'></Link>}</div> }
        { role>=ROLES.Invite && <div className={classArchives}>{(page!=="reference-edit" && page!=="reference-new") &&<Link to='/archives' key='archives'></Link>}</div> }
        { role===ROLES.RegOne && <div className={classParametres}>{(page!=="reference-edit" && page!=="reference-new") &&<Link to='/parametres' key='parametres'></Link>}</div> }
        { role>ROLES.Invite && <div className={classCompte}>{(page!=="reference-edit" && page!=="reference-new") &&<Link to='/compte' key='compte'></Link>}</div> }
        { (isOnline && role===ROLES.RegOne) && <div className="picto_ssmenu qrcode fixed"><Link to="/qreader"></Link></div>}
        </div>
    )
}
export default Menu1