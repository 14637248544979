import ReferenceFiche from '../ReferenceFiche'
import ReferenceEdit from '../ReferenceEdit'
import References from '../References'
import Parametres from '../Parametres'
import Users from '../Users'
import Lieux from '../Lieux'
import Compte from '../Compte'
import Logs from '../Logs'
import Synchro from '../Synchro'
import Caisses from '../Caisses'
import CaisseEdit from '../CaisseEdit'
import { Link } from 'react-router-dom'
import { listeTypeRech } from '../../hooks/data'
import { useState } from 'react'

function Content({action, actionChange, onListChange, getSelection, initSelection, menuAction, setMenuAction, filtres, ordre, page, refId, rech, isOnline, validations,setValidations,statutValidation,setStatutValidation, refs, setRefs, refsAff, setRefsAff}){

    let ariane=""
    switch(page) {
        case "references" :
            ariane="Références"
            break
        case "archives" :
            ariane="Références en stock"
            break
        case "references-validees" :
            ariane="Références validées à traiter"
            break
        case "caisses" :
            ariane="Caisses"
            break
        case "caisse" :
            ariane=<><Link className='urlNaked' to="/caisses">Caisses</Link> <span className='fs08'>&#62;</span> contenu</>
            break
        case "parametres" :
            ariane="Catégories, familles..."
            break
        case "users" :
            ariane="Utilisateurs"
            break
        case "lieux" :
            ariane="Lieux"
            break
    }

    return(
        <div id='content' className='content hideScrollBar'>
            <div className='container'>
                <span className='ariane'>{ariane}</span>
            </div>
            { page==="reference" && (
                <ReferenceFiche validations={validations} setValidations={setValidations} statutValidation={statutValidation} setStatutValidation={setStatutValidation} refId={refId} action={action} isOnline={isOnline}/>
            )}
            { page==="references" && (
                <References page={page} action={action} rech={rech} onListChange={onListChange} actionChange={actionChange} getSelection={getSelection} initSelection={initSelection} menuAction={menuAction} setMenuAction={setMenuAction} filtres={filtres} ordre={ordre} isOnline={isOnline} refs={refs} setRefs={setRefs} refsAff={refsAff} setRefsAff={setRefsAff}/>
            )}
            { page==="references-validees" && (
                <References page={page} action={action} rech={rech} onListChange={onListChange} actionChange={actionChange} getSelection={getSelection} initSelection={initSelection} menuAction={menuAction} setMenuAction={setMenuAction} filtres={filtres} ordre={ordre} isOnline={isOnline} refs={refs} setRefs={setRefs} refsAff={refsAff} setRefsAff={setRefsAff}/>
            )}
            { page==="references-encours" && (
                <References page={page} action={action} rech={rech} onListChange={onListChange} actionChange={actionChange} getSelection={getSelection} initSelection={initSelection} isOnline={isOnline} refs={refs} setRefs={setRefs} refsAff={refsAff} setRefsAff={setRefsAff}/>
            )}
            { page==="reference-edit" && (
                <ReferenceEdit refId={refId} action={action} isOnline={isOnline} local={false}/>
            )}
            { page==="reference-edit-local" && (
                <ReferenceEdit refId={refId} action={action} isOnline={isOnline} local={true}/>
            )}
            { page==="reference-new" && (
                <ReferenceEdit refId="001" action={action} isOnline={isOnline}/>
            )}
            { page==="archives" && (
                <References page={page} action={action} rech={rech} onListChange={onListChange} actionChange={actionChange} getSelection={getSelection} initSelection={initSelection} menuAction={menuAction} setMenuAction={setMenuAction} filtres={filtres} ordre={ordre} isOnline={isOnline} refs={refs} setRefs={setRefs} refsAff={refsAff} setRefsAff={setRefsAff}/>
            )}
            { page==="caisses" && (
                <Caisses page={page} action={action} actionChange={actionChange} getSelection={getSelection} initSelection={initSelection} menuAction={menuAction} setMenuAction={setMenuAction} filtres={filtres} ordre={ordre} isOnline={isOnline}/>
            )}
            { page==="caisse-edit" && (
                <CaisseEdit refId={refId} action={action} isOnline={isOnline}/>
            )}
            { page==="caisse-new" && (
                <CaisseEdit refId={"001"} action={action} isOnline={isOnline}/>
            )}
            { page==="caisse" && (
                <References page={page} action={action} rech={rech} actionChange={actionChange} onListChange={onListChange} getSelection={getSelection} initSelection={initSelection} menuAction={menuAction} setMenuAction={setMenuAction} filtres={filtres} ordre={ordre} isOnline={isOnline} idCaisse={refId} refs={refs} setRefs={setRefs} refsAff={refsAff} setRefsAff={setRefsAff}/>
            )}
            { page==="parametres" && (
                <Parametres page={page} action={action} isOnline={isOnline}/>
            )}
            { page==="users" && (
                <Users page={page} action={action} isOnline={isOnline}/>
            )}
            { page==="lieux" && (
                <Lieux page={page} action={action} isOnline={isOnline}/>
            )}
            { page==="compte" && (
                <Compte action={action} isOnline={isOnline}/>
            )}
            { page==="logs" && (
                <Logs action={action} isOnline={isOnline}/>
            )}
            { page==="synchro" && (
                <Synchro page={page} action={action} actionChange={actionChange} isOnline={isOnline}/>
            )}
        </div>
    )
}
export default Content