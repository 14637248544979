import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      <div className="container">
        <div className="row gx-1">
          <div className="col col-md-4 col-12"><div>test col 1<br/><button className="btn btn-primary">Test</button></div></div>
          <div className="col col-md-8 col-12"><div>test col 2</div></div>
        </div>
      </div>
      <h1 style={{ color: "red", fontSize: 100 }}>404</h1>
      <h3>Page Not Found</h3>
      <p>
        <Link to="/testpwa">Go Home</Link>
      </p>
  </div>
  )
}

export default NotFound