import { useState, useEffect } from 'react'
import axios from 'axios'
import { db } from "../hooks/db"
import { useLiveQuery } from "dexie-react-hooks"
import {useNavigate} from 'react-router-dom'
import { Button, Modal,Tabs,Tab } from 'react-bootstrap'
import { XLg, CheckLg, CloudCheck, Trash } from "react-bootstrap-icons"
import {mess1List, mess2List, statuts, APICALL_URL, IMGS_URL, auteurs, phases, zones, rangees, niveaux } from '../hooks/data'
import { errorAff, paramMajBddLocale, dateToFr, nl2br } from '../hooks/functions'
import * as exif from '@ginpei/exif-orientation'

function ReferenceEdit({page, refId, isOnline, local}){
  const userLocal = JSON.parse(localStorage.getItem("user"))
  const [success, setSuccess] = useState(false)
  const [mess1, setMess1] = useState(mess1List[0])
    const [mess2, setMess2] = useState(mess2List[0])
    const [errMsg, setErrMsg] = useState('')
    const [objet, setObjet] = useState()
    const [statut, setStatut] = useState(0)
    const [createur, setCreateur] = useState()
    const [categorie, setCategorie] = useState('none')
    const [famille, setFamille] = useState('none')
    const [typologie, setTypologie] = useState('none')
    const [phase, setPhase] = useState('none')
    const [auteur, setAuteur] = useState('none')
    const [editeur, setEditeur] = useState('none')
    const [description, setDescription] = useState("")
    const [lieu, setLieu] = useState("none")
    const [typeStockage,setTypeStockage] = useState("none")
    const [infoStockage,setInfoStockage] = useState("")
    const [zone, setZone] = useState("none")
    const [rangee, setRangee] = useState("none")
    const [niveau, setNiveau] = useState("none")
    const [caisse, setCaisse] = useState("none")
    const [mouvements, setMouvements] = useState([])
    const [show, setShow] = useState(false)
    const [save, setSave] = useState(false)
    const [saveLocal, setSaveLocal] = useState(false)
    const [saveAndClose, setSaveAndClose] = useState(false)
    const [saveAndCloseLocal, setSaveAndCloseLocal] = useState(false)
    const [saved, setSaved] = useState(false)
    const [savedLocal, setSavedLocal] = useState(false)
    const [referenceId, setReferenceId] = useState(refId)
    const [showAjoutCar, setShowAjoutCar] = useState(false)
    const [showModifCar, setShowModifCar] = useState(false)
    const [showModifDoc, setShowModifDoc] = useState(false)
    const [showModifMvt, setShowModifMvt] = useState(false)
    const [carSup, setCarSup] = useState()
    const [currentIndex,setCurrentIndex] = useState()
    const [caract, setCaract]= useState([])
    const [carSelected, setCarSelected] = useState();
    const [carSelectedIndex, setCarSelectedIndex] = useState();
    const [modifNewVal, setModifNewVal] = useState("")
    const [listeCats, setListeCats] = useState([])
    const [listeCaracs, setListeCaracs] = useState([])
    const [listeLieux, setListeLieux] = useState([])
    const [listeStockageTypes, setListeStockageTypes] = useState([])
    const [listeCaisses, setListeCaisses] = useState([])
    const [listeFamilles, setListeFamilles] = useState([])
    const [listeTypologies, setListeTypologies] = useState([])
    const [listeEditeurs, setListeEditeurs] = useState([])
    const [photos, setPhotos] = useState([])
    const [docs, setDocs] = useState([])
    const [modifDocNewTitle, setModifDocNewTitle] = useState("")
    const [docSelectedIndex, setDocSelectedIndex] = useState()
    const [mvtSelectedIndex, setMvtSelectedIndex] = useState()
    const [modifMvtLieu, setModifMvtLieu] = useState("")
    const [modifMvtDepart, setModifMvtDepart] = useState("")
    const [modifMvtRetour, setModifMvtRetour] = useState("")
    const [modifMvtInfos, setModifMvtInfos] = useState("")
    const [randomInt, setRandomInt] = useState()
    const [loaded, setLoaded] = useState(false)

    function loadElements(){
      db.categories.where('id').above(0).sortBy("ordre").then(
        res=>{
          let tempArray=[]
          res.map(car=>{
            tempArray[car.id]=car
          })
          setListeCats (tempArray)

          db.caracteristiques.where('id').above(0).sortBy("ordre").then(
            res=>{
              let tempArray=[]
              res.map(car=>{
                tempArray[car.id]=car
              })
              setListeCaracs(tempArray)

              db.lieux.where('id').above(0).sortBy("id").then(
                res=>{
                  let tempArray=[]
                  res.map(car=>{
                    tempArray[car.id]=car
                  })
                  setListeLieux(tempArray)

                  db.stockage_types.where('id').above(0).sortBy("id").then(
                    res=>{
                      let tempArray=[]
                      res.map(car=>{
                        tempArray[car.id]=car
                      })
                      setListeStockageTypes(tempArray)

                      db.objets.where('id').equals(parseInt(refId)).sortBy("id").then(
                        res=>{
                          if(local){
                            let objet=res[0]
                            setCategorie(objet.categorie)
                            setFamille(objet.famille)
                            setTypologie(objet.typologie)
                            setPhase(objet.phase)
                            setAuteur(objet.auteur)
                            setEditeur(objet.editeur)
                            setDescription(objet.description)
                            setCaract(objet.caracteristiques)
                            setTypeStockage(objet.stock_type)
                            setCaisse(objet.stock_caisse)
                          }
                          db.stockage.where({id_ref:parseInt(refId)}).sortBy("id").then(
                            res=>{
                              if(local){
                                res=res[0]
                                setLieu(res.lieu)
                                setZone(res.zone)
                                setRangee(res.rangee)
                                setNiveau(res.niveau)
                                setInfoStockage(res.infos)
                              }

                              db.medias.where({id_ref:parseInt(refId),type:'photo'}).sortBy("id").then(
                                res=>{
                                  if(local){
                                    setPhotos(res)
                                  }
                                  db.medias.where({id_ref:parseInt(refId),type:'doc'}).sortBy("id").then(
                                    res=>{
                                      if(local){
                                        setDocs(res)
                                      }
                                      setLoaded(true)
                                      setSuccess(true)
                                      
                                      db.familles.where('id').above(0).sortBy("ordre").then(
                                        res=>{
                                          let tempArray=[]
                                          res.map(car=>{
                                            tempArray[car.id]=car
                                          })
                                          setListeFamilles(tempArray)

                                          db.typologies.where('id').above(0).sortBy("ordre").then(
                                            res=>{
                                              let tempArray=[]
                                              res.map(car=>{
                                                tempArray[car.id]=car
                                              })
                                              setListeTypologies(tempArray)

                                              db.editeurs.where('id').above(0).sortBy("ordre").then(
                                                res=>{
                                                  let tempArray=[]
                                                  res.map(car=>{
                                                    tempArray[car.id]=car
                                                  })
                                                  setListeEditeurs(tempArray)

                                                  db.caisses.where('id').above(0).toArray().then(
                                                    res=>{
                                                      let tempArray=[]
                                                      res.map(car=>{
                                                        tempArray[car.id]=car.id
                                                      })
                                                      setListeCaisses(tempArray)
                                                    }
                                                  )
                                                }
                                              )
                                            }
                                          )
                                        }
                                      )
                                    }
                                  )
                                }
                              )
                            }
                          )
                        }
                      )
                    }
                  )
                }
              )
            }
          )
        }
      )
    }

    useEffect(() => {
      loadElements()
      if(isOnline && !local && referenceId!=="001"){
      setSuccess(false)
        const postData = {
          action:"getParametres",
          referenceId:refId,
          accessToken: userLocal.accessToken,
          userId: userLocal.idUser
        }
      axios.post(APICALL_URL,postData,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(res => {
          if(res.data?.action==="edit"){
            const objet=res.data.objet[0]
            const photos=res.data.photos
            const docs=res.data.docs
            const mouvements=res.data.mouvements
            setObjet(objet)
            setStatut(objet.statut)
            setCreateur(res.data.createur)
            setCategorie(objet.categorie)
            setFamille(objet.famille)
            setTypologie(objet.typologie)
            setPhase(objet.phase)
            setAuteur(objet.auteur)
            setEditeur(objet.editeur)
            setDescription(objet.description)
            setCaract(JSON.parse(objet.caracteristiques))
            setLieu(objet.lieu)
            setZone(objet.zone)
            setRangee(objet.rangee)
            setNiveau(objet.niveau)
            setTypeStockage(objet.stock_type)
            setInfoStockage(objet.infos)
            setCaisse(objet.stock_caisse)
            setPhotos(photos)
            setDocs(docs)
            setMouvements(mouvements)
          }
          setRandomInt(Math.floor(Math.random() * 100000))
            // Maj Bdd locale
            paramMajBddLocale(res.data.bdd)
        })
    } else {
      if(userLocal.roles>=4001){
        setSuccess(true)
      } else {
        setSuccess(true)
      }
    }
  }, []);

  useEffect(() => {
    if(save || saveAndClose) {
      setMess1(mess1List[1])
      setMess2(mess2List[1])
      setSuccess(false)
      if(isOnline){
        const postData = {
          action:"saveReference",
          referenceId:referenceId,
          statut:statut,
          categorie:categorie,
          famille:famille,
          typologie:typologie,
          caracteristiques:caract,
          phase:phase,
          auteur:auteur,
          editeur:editeur,
          description:description,
          lieu:lieu,
          typeStockage:typeStockage,
          infoStockage:infoStockage,
          zone:zone,
          rangee:rangee,
          niveau:niveau,
          caisse:caisse,
          photos:photos,
          docs:docs,
          mouvements:mouvements,
          accessToken: userLocal.accessToken,
          userId: userLocal.idUser
        }
        axios.post(APICALL_URL,postData,
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          })
          .then(res => {
            setRandomInt(Math.floor(Math.random() * 100000))
            setPhotos(res.data.photos)
            setDocs(res.data.docs)
            setReferenceId(res.data.refId)
            setSuccess(true)
            setSave(false)
            if(saveAndClose) navigate(-1)
            setSaved(true)
          })
      }
    }
  }, [save, saveAndClose]);

  useEffect(() => { // enregistrement en local
    if(saveLocal || saveAndCloseLocal) {
      setMess1(mess1List[3])
      setMess2(mess2List[3])
      setSuccess(false)
      
      const postData = {
        action:"saveReference",
        referenceId:referenceId,
        categorie:categorie,
        famille:famille,
        typologie:typologie,
        caracteristiques:caract,
        phase:phase,
        auteur:auteur,
        editeur:editeur,
        description:description,
        lieu:lieu,
        typeStockage:typeStockage,
        infoStockage:infoStockage,
        zone:zone,
        rangee:rangee,
        niveau:niveau,
        caisse:caisse,
        photos:photos,
        docs:docs,
        accessToken: userLocal.accessToken,
        userId: userLocal.idUser
      }
      if(referenceId!=="001"){
        saveLocalObjet(postData,"update")
      } else {
        saveLocalObjet(postData,"insert")
      }
    }
  }, [saveLocal, saveAndCloseLocal]);

  useEffect(() => {
    if(mvtSelectedIndex==="new") {
      setShowModifMvt(true)
    }
  }, [mvtSelectedIndex]);

  useEffect(() => {
    if(showModifMvt===false) {
      setMvtSelectedIndex("")
    }
  }, [showModifMvt]);
  
  async function saveLocalObjet(postData,action) {
    // objets: '++id, categorie, famille, typologie, phase, auteur, editeur, description, caracteristiques, statut, stock_state, stock_type'
    const data={
      categorie:postData.categorie,
      famille:postData.famille,
      typologie:postData.typologie,
      phase:postData.phase,
      auteur:postData.auteur,
      editeur:postData.editeur,
      description:postData.description,
      caracteristiques:postData.caracteristiques,
      statut:0,
      stock_state:0,
      stock_type:postData.typeStockage,
      stock_caisse:postData.caisse
    }
    if(action==="insert"){
      try {
        const idObjet = await db.objets.add(data)
        setReferenceId(idObjet)
        saveLocalMedias(postData,idObjet)
        saveStockage(postData,idObjet)
      } catch (error) {
        //
      }
    }
    if(action==="update"){
      try {
        const update = await db.objets.update(parseInt(referenceId),data);
        saveLocalMedias(postData,referenceId)
        saveStockage(postData,referenceId)
      } catch (error) {
        //
      }
    }
  }

  async function saveStockage(postData,idObjet) {
    // stockage: '++id, id_ref, lieu, zone, rangee, niveau, infos',
    const data = {
      id_ref : parseInt(idObjet),
      lieu : lieu,
      zone : zone,
      rangee : rangee,
      niveau : niveau,
      infos : infoStockage,
      stock_caisse : caisse
    }
    db.stockage
    .where("id_ref").equals(parseInt(idObjet))
    .delete()
    .then(function (deleteCount) {
      const stocking = db.stockage.add(data);
    })
  }

  async function saveLocalMedias(postData,idObjet) {
    // medias: '++id, id_ref, type, nom, extension, poids, titre, ordre'
    const photos=postData.photos
    const docs=postData.docs
    const toPush=[]
    db.medias
    .where("id_ref").equals(parseInt(idObjet))
    .delete()
    .then(function (deleteCount) {
      photos.map(el=>{
          toPush.push({
            id_ref:parseInt(idObjet),
            type:'photo',
            nom:el.nom,
            extension:el.extension,
            poids:el.poids,
            titre:'',
            file:el.file,
            rotation:el.rotation
          })
      })
      docs.map(el=>{
          toPush.push({
            id_ref:parseInt(idObjet),
            type:'doc',
            nom:el.nom,
            extension:el.extension,
            poids:el.poids,
            titre:el.titre,
            file:el.file
          })
      })
      db.medias.bulkPut(toPush).then(
        majLocalCallback(photos,docs)
      )
    })
  }

  function majLocalCallback(photos,docs){
    setPhotos(photos)
    setDocs(docs)
    setSuccess(true)
    setSaveLocal(false)
    if(saveAndCloseLocal) navigate("/synchro")
    setSavedLocal(true)
  }

  const handleClose = () => setShow(false)
  const handleCloseAjoutCar = () => setShowAjoutCar(false)
  const handleCloseModifCar = () => setShowModifCar(false)
  const handleCloseModifDoc = () => setShowModifDoc(false)
  const navigate = useNavigate()
  const cancel = () => { navigate(-1)};

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

function affPoids(bytes){
  return (bytes/(1024*1000)).toFixed(2)+" Mb"
}

  function addCar(){
    let newArr = [...caract];
    let ind=parseInt(carSup)
    newArr.push({id:ind,cat:categorie,cont:modifNewVal})
    setCaract(newArr)
    setShowAjoutCar(false)
    setModifNewVal("")
    setCarSup(0)
  }

  function majCars() {
    let newArr = [...caract];
    newArr[carSelectedIndex].cont = modifNewVal;
    setCaract(newArr);
    setShowModifCar(false)
    setModifNewVal("")
  }

  const moveCar = (index,sens) => e => {
    let newIndex=index;
    sens==="up" ? newIndex-- : newIndex++
    if(newIndex<caract.length) {
      let newArr = array_move([...caract], index, newIndex)
      setCaract(newArr)
    }
  }
  
  const supCar= (index) => e => {
    let newArr = [...caract]
    newArr.splice(index,1)
    setCaract(newArr)
    setShowModifCar(false)
    setModifNewVal("")
  }

  const modifCar = index => e => {
    setCarSelected(caract[index])
    setCarSelectedIndex(index)
    setModifNewVal(caract[index].cont)
    setShowModifCar(true)
  }

  const showModalDoc = index => e => {
    setModifDocNewTitle(docs[index].titre)
    setDocSelectedIndex(index)
    setShowModifDoc(true)
  }

  const showModalMvt = index => e => {
    setMvtSelectedIndex(index)
    setModifMvtLieu(mouvements[index].lieu)
    setModifMvtDepart(mouvements[index].depart)
    setModifMvtRetour(mouvements[index].retour)
    setModifMvtInfos(mouvements[index].infos)
    setShowModifMvt(true)
  }

  function modifDoc () {
    let newArr = [...docs]
    newArr[docSelectedIndex]["titre"]= modifDocNewTitle
    setDocs(newArr)
    setShowModifDoc(false)
  }

  const addDoc = (e) => {
    let file = e.target.files[0]
    let nom=file.name
    let titre=file.name
    let poids=file.size
    let extension=file.type
    let fileReader = new FileReader()
    let newArr = [...docs];
    fileReader.readAsDataURL(file)
    fileReader.onload = (event) => {
      newArr.push({id:'0',id_ref:referenceId,type:"doc",nom:nom, poids:poids, extension:extension, file:event.target.result, titre:titre})
      setDocs(newArr)
    }
  }
  function supDoc(index){
    let newArr = [...docs];
    newArr.splice(index,1)
    setDocs(newArr);
  }

  function moveDoc (index,sens) {
    let newIndex=index;
    sens==="up" ? newIndex-- : newIndex++
    if(newIndex<docs.length) {
      let newArr = array_move([...docs], index, newIndex)
      setDocs(newArr)
    }
  }

  function supPhoto(index){
    let newArr = [...photos];
    newArr.splice(index,1)
    setPhotos(newArr);
  }

  function movePhoto (index,sens) {
    let newIndex=index;
    sens==="up" ? newIndex-- : newIndex++
    let newArr = array_move([...photos], index, newIndex)
    setPhotos(newArr)
  }

  function rotatePhoto (index,sens) {
    let rot=parseInt(photos[index].rotation)
    sens==="up" && rot==270 ? rot=0 : rot+=90
    rot=rot.toString()
    let newArr = [...photos];
    newArr[index].rotation=rot
    setPhotos(newArr)
  }
  
const addPhoto = async (e) => {
  let file = e.target.files[0]
  let rot = "0"
  let orientation = await exif.getOrientation(file);
  if(orientation!=undefined) {
    rot = orientation.rotation.toString()
  }
  let poids=file.size
  let src = URL.createObjectURL(file)
  let newArr = [...photos];
  getBase64(file)
      .then(result => {
        newArr.push({id:'0',id_ref:referenceId,type:"photo",poids:poids,nom:file.name.split('.')[0],extension:'jpg',src:src,file:result,rotation:rot})
        setPhotos(newArr)
      })
      .catch(err => {
        console.log(err);
      })
}

function supMvt(index){
  let newArr = [...mouvements];
  newArr.splice(index,1)
  setMouvements(newArr);
}

function majMvts() {
  let newArr = [...mouvements]
  let index=mvtSelectedIndex
  if(mvtSelectedIndex==="new"){
    newArr.push({lieu:"",depart:"",retour:"",infos:""})
    index=newArr.length-1
    setMvtSelectedIndex(index)
  }
  newArr[index].lieu = modifMvtLieu
  newArr[index].depart = modifMvtDepart
  newArr[index].retour = modifMvtRetour
  newArr[index].infos = modifMvtInfos
  setMouvements(newArr)
  setShowModifMvt(false)
  setModifMvtLieu("")
  setModifMvtDepart("")
  setModifMvtRetour("")
  setModifMvtInfos("")
}

const modalModifDoc=<Modal show={showModifDoc} onHide={handleCloseModifDoc} centered>
<Modal.Header closeButton>
  <Modal.Title>
    Modifier l'intitulé d'un document
    </Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className='mb-2'><b>Intitulé</b></div>
<input type="text" value={modifDocNewTitle} onChange={(e)=>setModifDocNewTitle(e.target.value)} className="w100" />
  </Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleCloseModifDoc}>
  <XLg /> Annuler
  </Button>
  <Button variant="primary" onClick={modifDoc}>
    <CheckLg/> Modifier
  </Button>
</Modal.Footer>
</Modal>

const modalAjoutCar=<Modal show={showAjoutCar} onHide={handleCloseAjoutCar} centered>
<Modal.Header closeButton>
  <Modal.Title>
    Ajouter une caractéristique
    </Modal.Title>
</Modal.Header>
<Modal.Body>
  <select className='w100' value={carSup} onChange={(e)=>setCarSup(e.target.value)}>
    <option value='0'>Choisir…</option>
    { listeCaracs.map((car,i) =>
    car.cat===categorie && <option key={i} value={car.id}>{car.nom}</option>
    )}
  </select>
  <input type="text" value={modifNewVal} onChange={(e)=>setModifNewVal(e.target.value)} className="mt-2 w100" />
  </Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleCloseAjoutCar}>
  <XLg /> Annuler
  </Button>
  <Button variant="primary" onClick={addCar}>
    <CheckLg/> Ajouter
  </Button>
</Modal.Footer>
</Modal>

const modalModifCar=<Modal show={showModifCar} onHide={handleCloseModifCar} centered>
<Modal.Header closeButton>
  <Modal.Title>
    Modifier une caractéristique
    </Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className='mb-2'><b>{listeCaracs[carSelected?.id]?.nom}</b></div>
<input type="text" value={modifNewVal} onChange={(e)=>setModifNewVal(e.target.value)} className="w100" />
  </Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleCloseModifCar}>
  <XLg /> Annuler
  </Button>
  <Button variant="primary" onClick={majCars}>
    <CheckLg/> Modifier
  </Button>
</Modal.Footer>
</Modal>

const modalModifMvt=<Modal show={showModifMvt} onHide={(e)=>setShowModifMvt(false)} centered>
<Modal.Header closeButton>
  <Modal.Title>
    {mvtSelectedIndex==="new" ? "Ajouter" : "Modifier"} un mouvement
    </Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className='mb-1'><b>Lieu</b></div>
  <input type="text" value={modifMvtLieu} onChange={(e)=>setModifMvtLieu(e.target.value)} className="w100" />
  <div className='mt-2 mb-1 row'>
    <div className="col-6"><b>Départ</b><br/>
  <input type="date" value={modifMvtDepart} onChange={(e)=>setModifMvtDepart(e.target.value)}></input>
  </div>
  <div className="col-6"><b>Retour</b><br/>
  <input type="date" value={modifMvtRetour} onChange={(e)=>setModifMvtRetour(e.target.value)}></input>
  </div>
  </div>
  <div className='mt-2 mb-1'><b>Infos</b></div>
  <textarea value={modifMvtInfos} onChange={(e)=>setModifMvtInfos(e.target.value)} className="w100" />
  </Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={(e)=>setShowModifMvt(false)}>
  <XLg /> Annuler
  </Button>
  <Button variant="primary" onClick={majMvts}>
    <CheckLg/> {mvtSelectedIndex==="new" ? "Ajouter" : "Modifier"}
  </Button>
</Modal.Footer>
</Modal>
    
    const navig = <div className='barre_save'>
      <div className='container tar'>
      <Button className='mgr1' size="sm" variant="secondary" disabled={!loaded && "disabled"} onClick={cancel}>
          <XLg/> Fermer
      </Button>
      {(!local && isOnline) &&
      <>
      <Button className='mgr1' size="sm" variant="primary" disabled={!loaded && "disabled"} onClick={e=>setSaveAndClose(true)}>
          <CloudCheck/>  Enregistrer et fermer
      </Button>
      <Button className='mgr1' size="sm" variant="primary" disabled={!loaded && "disabled"} onClick={e=>setSave(true)}>
      <CloudCheck/>  Enregistrer
      </Button>
      </>
      }

      {(local || !isOnline) &&
      <>
      <Button className='mgr1' size="sm" variant="primary" disabled={!loaded && "disabled"} onClick={e=>setSaveAndCloseLocal(true)}>
          <CheckLg/>  Enregistrer et fermer
      </Button>
      <Button size="sm" variant="primary" disabled={!loaded && "disabled"} onClick={e=>setSaveLocal(true)}>
      <CheckLg/>  Enregistrer
      </Button>
      </>
      }
      </div>
</div>

    return(
    <div className='container form'>
      { (isOnline || userLocal.roles===4001) ? 
        <> 
          { navig }
          { modalAjoutCar }
          { modalModifCar }
          { modalModifDoc }
          { modalModifMvt }
          { !success && <p className="mess">{mess1}</p>}
        { success && <p className="mess mess_vert mess_court">{mess2}</p>}
          { errMsg!=="" && <p className="mess alerte">{errMsg}</p>}
          <div className='fs08'>
            {referenceId!=="001"
            ?
            <>
            <b>Référence {local && "locale"} : {referenceId}</b><br/>
            {(!local && objet) &&
            <>
            <b>Création</b> : {dateToFr(objet.date_creation.substring(0, 10))} – {createur.prenom.substring(0,1)}. {createur.nom}<br/>
            <div className="mt-2">
            <b>Statut</b> :&nbsp;
            {userLocal.roles>=4001 &&
            <select value={statut} onChange={e=>setStatut(e.target.value)} className="form-select form-select-sm w-fit-content d-inline-block">
              {statuts.map((stat,i)=>
              <option key={i} value={i}>{stat}</option>
                )}
              </select>
            }
            {userLocal.roles<4001 &&
              <span>{statuts[statut]}</span>
            }
            </div>
            </>
            }
            </>
            :
            <b>Nouvelle référence {(local || !isOnline) && "locale"}</b>}
          </div>

          <Tabs defaultActiveKey="first" className='mt-4'>
        <Tab eventKey="first" title="Identification & Caractéristiques">
          <div className="row">
            <div className="col-md-5 pt-4">
              <div className="row">
              <h4 className='mb-4'>Identification</h4>
                <div className="col-3">
                  <label>Catégorie</label>
                </div>
                <div className="col-9 mb-3">
                <select name="categorie" value={categorie} onChange={e => setCategorie(e.target.value)} className="w100 hinput">
              <option value='0'>Choisir…</option>
              { listeCats.map(cat=><option value={cat.id} key={cat.nom+cat.id}>{cat.nom}</option>)}
      </select>
                </div>
                <div className="col-3">
                  <label>Famille</label>
                </div>
                <div className="col-9 mb-3">
                <select name="familles" className="w100" value={famille} onChange={e => setFamille(e.target.value)}>
                  <option value='0'>Choisir…</option>
                  { listeFamilles.map(op=>
                  op.cat===categorie && <option value={op.id} key={op.nom+op.id}>{op.nom}</option>
                    )}
                </select>
                </div>
                <div className="col-3">
                  <label>Typologie</label>
                </div>
                <div className="col-9 mb-3">
                  <select name="typologies" className="w100" value={typologie} onChange={e => setTypologie(e.target.value)}>
                  <option value='0'>Choisir…</option>
                  { listeTypologies.map(op=>
                  op.cat===categorie && <option value={op.id} key={op.nom+op.id}>{op.nom}</option>
                    )}
                </select>
                </div>
                <div className="col-3">
                  <label>Phase</label>
                </div>
                <div className="col-9 mb-3">
                  <select name="phase" value={phase} onChange={e => setPhase(e.target.value)} className="w100">
                    <option value='0'>Choisir…</option>
                    {phases.map(phase=><option key={phase.id} value={phase.id}>{phase.nom}</option>)}
                  </select>
                </div>
                <div className="col-3">
                  <label>Auteur</label>
                </div>
                <div className="col-9 mb-3">
                  <select name="auteur" value={auteur} onChange={e => setAuteur(e.target.value)} className="w100">
                    <option value='0'>Choisir…</option>
                    {auteurs.map(auteur=><option key={auteur.id} value={auteur.id}>{auteur.nom}</option>)}
                  </select>
                </div>
                <div className="col-3">
                  <label>Editeur</label>
                </div>
                <div className="col-9 mb-3">
                  <select name="editeurs" className="w100" value={editeur} onChange={e => setEditeur(e.target.value)}>
                  <option value='0'>Choisir…</option>
                  { listeEditeurs.map(op=>
                  op.cat===categorie && <option value={op.id} key={op.nom+op.id}>{op.nom}</option>
                    )}
                </select>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 pt-4">
              <h4 className='mb-4'>Caractéristiques</h4>
              <>
             {caract?.map((car,index)=>
              <div key={"div"+index} className="itemParam">
                <div className="row">
                  <div onClick={modifCar(index)} className="col-3">
                    <label>{listeCaracs[car.id].nom}</label>
                  </div>
                  <div onClick={modifCar(index)} className="col-5">
                    <div>{car.cont}</div>
                  </div>
                  <div className="col-4 text-right">
                  {index!==0 && <span onClick={moveCar(index,"up")} className="picto_admin up mr-4"></span>}
                  {index!==(caract.length-1) && <span onClick={moveCar(index,"down")} className="picto_admin down mr-4"></span>}
                  <span onClick={supCar(index)} className="picto_admin sup d-inline-block"></span>
                  </div>
                </div>
              </div>
              )}
              <div className="upZone upZoneCar mt-3" onClick={(e)=>setShowAjoutCar(true)}>
                <span className='picto_admin plus'></span>
              </div>
              <div className="col-12 mt-3">
                <label>Description libre</label>
                <textarea value={description} onChange={e=>setDescription(e.target.value)} className="w100" />
                </div>
              </>
            </div>
          </div>
        </Tab>
        { userLocal.roles>=4001 &&
        <Tab eventKey="second" title="Localisation & Mouvements">
        <div className="row">
            <div className="col-md-5 pt-4">
              <div className="row">
                <h4 className='mb-4'>Localisation</h4>
                <div className="col-5">
                  <label>Emplacement</label>
                </div>
                <div className="col-7 mb-3">
                  <select name="type_stockage" value={typeStockage} onChange={e => setTypeStockage(e.target.value)} className="w100">
                    <option value='0'>Choisir…</option>
                    {listeStockageTypes.map(res=><option key={res.id} value={res.id}>{res.nom}</option>)}
                  </select>
                </div>
                {typeStockage==="1" &&
                <>
                <div className="col-5">
                  <label>Lieu de stockage</label>
                </div>
                <div className="col-7 mb-3">
                  <select name="lieu" value={lieu} onChange={e => setLieu(e.target.value)} className="w100">
                    <option value='0'>Choisir…</option>
                    {listeLieux.map(lieu=><option key={lieu.id} value={lieu.id}>{lieu.nom}</option>)}
                  </select>
                </div>
                  <div className="col-5">
                    <label>Zone</label>
                  </div>
                  <div className="col-7 mb-3">
                    <select name="zone" value={zone} onChange={e => setZone(e.target.value)} className="w100">
                      <option value=''>Choisir…</option>
                      {zones.map(res=><option key={res} value={res}>{res}</option>)}
                    </select>
                  </div>
                  <div className="col-5">
                    <label>Rangée</label>
                  </div>
                  <div className="col-7 mb-3">
                    <select name="rangee" value={rangee} onChange={e => setRangee(e.target.value)} className="w100">
                      <option value=''>Choisir…</option>
                      {rangees.map(res=><option key={res} value={res}>{res}</option>)}
                    </select>
                  </div>
                  <div className="col-5">
                    <label>Niveau</label>
                  </div>
                  <div className="col-7 mb-3">
                    <select name="niveau" value={niveau} onChange={e => setNiveau(e.target.value)} className="w100">
                      <option value=''>Choisir…</option>
                      {niveaux.map(res=><option key={res} value={res}>{res}</option>)}
                    </select>
                  </div>
                </>
                }
                {typeStockage==="2" &&
                  <>
                  <div className="col-5">
                    <label>Caisse</label>
                  </div>
                  <div className="col-7 mb-3">
                    <select name="niveau" value={caisse} onChange={e => setCaisse(e.target.value)} className="w100">
                      <option value='0'>Choisir…</option>
                      {listeCaisses.map(res=><option key={res} value={res}>C{res}</option>)}
                    </select>
                  </div>
                  </>
                }
                <div className="col-12">
                <label>Infos stockage</label>
                <textarea value={infoStockage} onChange={e=>setInfoStockage(e.target.value)} className="w100" />
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 pt-4">
              <h4 className='mb-4'>Mouvements</h4>
              {(local || !isOnline) &&
              <p><i>Fonctionnalité non disponible en local</i></p>
              }
              {(!local && isOnline) &&
              <>
              {mouvements.map((mvt,index)=>
                <div key={index} className="itemDoc">
                  <div className='row'>
                  <div onClick={showModalMvt(index)} className="col-8">
                    <span><b>{mvt.lieu}</b></span><br/>
                    <div className='fs08'>Départ : {dateToFr(mvt.depart)}  / Retour : {dateToFr(mvt.retour)}<br/>
                    {mvt.infos}</div>
                  </div>
                  <div className="col-4 flex-bottom-right">
                    <span className="picto_admin sup d-inline-block" onClick={e=>supMvt(index)}></span>
                  </div>
                  </div>
                </div>
                )}
                <div className='col-12'>
                    <div className="upZone upZoneDoc" onClick={e=>setMvtSelectedIndex("new")}>
                      <span className='picto_admin plus'></span>
                    </div>
                  </div>
                  </>
              }
            </div>
          </div>
        </Tab>
        }
        <Tab eventKey="third" title="Photos & documents">
          <div className="row">
              <div className="col-md-6 pt-4">
                <div className="row">
                  <h4 className='mb-4'>Photos</h4>
                  {photos.map((photo,index)=>
                    <div key={index} className='col-md-6'>
                      <div className='bloc_img'>
                        <div className="infos">{affPoids(photo.poids)}</div>
                        <div className="bloc_view">
                          { local ?
                            photo.id!=='0'
                            ?
                            <img src={photo.file} alt="preview" style={{transform: "rotate("+photo.rotation+"deg)"}} />
                            :
                            <img src={photo.src} alt="preview" />
                          :
                            photo.id!=='0'
                            ?
                            <img src={IMGS_URL+referenceId+"/md/"+photo.nom+"."+photo.extension+"?"+randomInt} alt="preview" style={{transform: "rotate("+photo.rotation+"deg)"}} />
                            :
                            <img src={photo.src} alt="preview" />
                          }
                        </div>
                        <div className='pictos_admin'>
                          { index!==0 &&
                        <span className="picto_admin left" onClick={e=>movePhoto(index,"up")}></span>
                          }
                          { index !==(photos.length-1) &&
                        <span className="picto_admin right" onClick={e=>movePhoto(index,"down")}></span>
                          }
                          { (photo?.rotation && refId!=="001") &&
                          <span className="picto_admin rot" onClick={e=>rotatePhoto(index,"up")}></span>
                        }
                        <span className="picto_admin sup" onClick={e=>supPhoto(index)}></span>
                          </div>
                      </div>
                    </div>
                  )}
                  <div className='col-md-6'>
                    <div className="upZone">
                      <label htmlFor="input_image"><span className='picto_admin plus'></span></label>
                    </div>
                  </div>
                </div>
            <input id='input_image' type="file" name="photo" accept="image/jpeg" onChange={addPhoto} className="d-none"></input>
            </div>
            <div className="col-md-6 pt-4">
              <div className="row">
                <div className="col-12">
                  <h4 className='mb-4'>Documents</h4>
                  {docs.map((doc,index)=>
                  <div key={index} className="itemDoc">
                    <div className='row'>
                    <div onClick={showModalDoc(index)} className="col-8">
                      <span><b>{doc.titre}</b></span><br/>
                      <span className='fs08'>{doc.nom} / {affPoids(doc.poids)}</span>
                    </div>
                    <div className="col-4 flex-bottom-right">
                      {index!==0 &&<span onClick={e=>moveDoc(index,"up")} className="picto_admin up mr-4"></span>}
                      {index!==(docs.length-1) && <span onClick={e=>moveDoc(index,"down")} className="picto_admin down mr-4"></span>}
                      <span className="picto_admin sup d-inline-block" onClick={e=>supDoc(index)}></span>
                    </div>
                    </div>
                  </div>
                  )}
                  <div className='col-12'>
                    <div className="upZone upZoneDoc">
                      <label htmlFor="input_doc"><span className='picto_admin plus'></span></label>
                      <input id='input_doc' type="file" name="doc" onChange={addDoc} className="d-none"></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
        </>
        :
        <div>Vous devez être connecté à internet</div> }
    </div>
    )
}

export default ReferenceEdit