function Compte({action, isOnline}){
    const userLocal = JSON.parse(localStorage.getItem("user"))
    const role=userLocal.roles
    console.log(userLocal)
    return(
    <div className="container">
        { userLocal.user && (
            <p>Utilisateur connecté : {userLocal.user} ! ({role})</p>
            )}
    </div>
    )
}

export default Compte