import { useState, useEffect } from 'react'
import axios from 'axios'
import {APICALL_URL} from '../hooks/data'
import { db } from '../hooks/db'
import { Link } from 'react-router-dom'

function CaisseInfos({refId,edit}) {

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const [success, setSuccess] = useState(false)
    const [type, setType] = useState(2)
    const [poids, setPoids] = useState("")
    const [longueur, setLongueur] = useState("")
    const [profondeur, setProfondeur] = useState("")
    const [hauteur, setHauteur] = useState("")
    const [lieu, setLieu] = useState("")
    const [zone, setZone] = useState("")
    const [rangee, setRangee] = useState("")
    const [niveau, setNiveau] = useState("")
    const [infoStockage, setInfoStockage] = useState("")
    const [listeLieux, setListeLieux] = useState([])
    const [loaded, setLoaded] = useState(false)

    let types = {1:"transport",2:"stockage"}

    useEffect(()=> {
        loadLieux()
        loadCaisse()
    },[])

    function loadCaisse(){
              const postData = {
                action:"getCaisseInfos",
                referenceId:refId,
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
              }
            axios.post(APICALL_URL,postData,
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              })
              .then(res => {
                let caisse=res.data.caisse
                let dims=JSON.parse(caisse.dimensions)
                setType(caisse.type)
                setLongueur(dims[0])
                setProfondeur(dims[1])
                setHauteur(dims[2])
                setPoids(caisse.poids)
                setLieu(caisse.lieu)
                setZone(caisse.zone)
                setRangee(caisse.rangee)
                setNiveau(caisse.niveau)
                setInfoStockage(caisse.infos)
                setLoaded(true)
                setSuccess(true)
              })
    }

    function loadLieux(){
        db.lieux.where('id').above(0).sortBy("id").then(
            res=>{
              let tempArray=[]
              res.map(car=>{
                tempArray[car.id]=car
              })
              setListeLieux(tempArray)
            })
    }

    return (
        <div className='fs08 typo1'>
            <b>Réf.C{refId}</b> – Caisse de {types[type]}<br/>
            Poids {poids}kg – L.{longueur}cm x P.{profondeur}cm x H.{hauteur} cm<br/>
            {listeLieux[lieu]?.nom}
            {(zone!=="" && zone!=="0") && <span> – zone {zone}</span>}
            {(rangee!=="" && rangee!=="0") && <span> – rangée {rangee}</span>}
            {(niveau!=="" && niveau!=="0") && <span> – niveau {niveau}</span>}
            {edit &&
            <><br/>
            <Link className='btn btn-sm' to={"/caisses/edit/"+refId}><p className='picto_admin edit'></p></Link>
            </>
            }
        </div>
    )

}

export default CaisseInfos