import ReferenceFiche from "../ReferenceFiche"
import { useState, useEffect } from "react"
import Recherche from "../Recherche"
import { listeInitiales, prenoms, APICALL_URL } from "../../hooks/data"
import axios from 'axios'

function Over({action, actionChange, listeAff, viewOver, setViewOver,contenu,page,getFiltres,setRech,isOnline,validations,setValidations,statutValidation,setStatutValidation, refs, setRefs, refsAff, setRefsAff}){

    const [ref,setRef] = useState()
    const [refIndex, setRefIndex] = useState()
    const [showValidation, setShowValidation] = useState(false)
    const [sens, setSens] = useState()

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const initiales=listeInitiales[userLocal.idUser]
    const prenom=prenoms[userLocal.idUser]

    
    useEffect(() => {
        setRef()
        setRefIndex(listeAff.indexOf(contenu.id))
    }, [contenu])

    useEffect(() => {
        if((statutValidation===1 || statutValidation===2) && showValidation){
            let newVal = {...validations}
            newVal[initiales]=statutValidation
            setValidations(newVal)
        } else {
            setShowValidation(false)
        }
    }, [statutValidation])

    useEffect(() => {
        if((statutValidation===1 || statutValidation===2) && showValidation){
            let refId
            let statut=0
            if(!ref) {refId=contenu.id} else {refId=ref}
            if(validations?.eb!=0 && validations?.rb!=0) {
                statut=1
            }
            const postData = {
                action:"updateValidations",
                refId:refId,
                statut:statut,
                validations:JSON.stringify(validations),
                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
            }
            console.log(postData)
            axios.post(APICALL_URL,postData,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                // maj affichage
                let refsTemp=[...refs]
                refsTemp[refIndex].validations=JSON.stringify(validations)
                setRefs(refsTemp)
                setShowValidation(false)
            })
        }
    }, [validations])

    function navRef(sens){
        setSens(sens)
        let index=refIndex+sens
        if((initiales==="eb" || initiales==="rb") && statutValidation===0){
            setShowValidation(true)
        } else {
            setRef(listeAff[index])
            setRefIndex(index)
        }
    }

    function valide(val){
        if(val===statutValidation) setShowValidation(false)
        switch (val) {
            case 1 : setStatutValidation(1)
            break
            case 2 : setStatutValidation(2)
            break
            case 3 : setStatutValidation(3)
            break
        }
    }

    return(
        <div className={viewOver ? "overZone" : "d-none"} >
            <div onClick={()=>setViewOver(false)}  className="over-fond"></div>
            <span onClick={()=>setViewOver(false)} className="picto_navig closeBlc closeOver"></span>
            {(contenu.cont==="viewRef" && viewOver) &&
            <div className="container-over">
                <div className="navRef navLeft">
                    {refIndex!==0 && <span onClick={()=>navRef(-1)} className="picto_navig left1"></span>}
                </div>
                    {!ref &&<ReferenceFiche validations={validations} setValidations={setValidations} statutValidation={statutValidation} setStatutValidation={setStatutValidation} setShowValidation={setShowValidation} page={page} refId={contenu.id} isOnline={isOnline} />}
                    {ref &&<ReferenceFiche validations={validations} setValidations={setValidations} statutValidation={statutValidation} setStatutValidation={setStatutValidation} setShowValidation={setShowValidation} page={page} refId={ref} isOnline={isOnline} />}
                <div className="navRef navRight">
                    {refIndex!==(listeAff.length-1) && <span onClick={()=>navRef(1)} className="picto_navig right1"></span>}
                </div>
            </div>
             }
             {(contenu.cont==="viewRefLocal" && viewOver) &&
            <div className="container-over">
            <ReferenceFiche page={page} refId={contenu.id} isOnline={isOnline} local={true} />
            </div>
             }
             {(contenu.cont==="search" && viewOver) &&
             <div className="container-over">
             <Recherche type="ref" setViewOver={setViewOver} getFiltres={getFiltres} setRech={setRech} />
             </div>
            }
            {(showValidation && userLocal.roles===2001) &&
            <div className="zone-validation">
                <div className="bloc-validation tac">
                    {prenom}, quel est votre avis concernant l'archivage de cette référence&nbsp;?
                    <div className="btns-validation mt-4">
                    <button className="btn btn-success" onClick={e=>valide(1)}>On garde</button>
                    <button className="btn btn-dark mt-2" onClick={e=>valide(2)}>On jette</button>
                    <button className="btn btn-light mt-2" onClick={e=>valide(3)}>Je déciderai plus tard</button>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
export default Over