import Menu1 from './Menu1'
import Menu2 from './Menu2'
import Content from './Content'
import Over from './Over'
import { useState, useEffect } from 'react'
import { setSelectionRange } from '@testing-library/user-event/dist/utils'
import {APICALL_URL, filtres1, ordres1} from '../../hooks/data'

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {// when unmount component
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return status;
}

function Layout({page, refId}){
    const [action,setAction]=useState(null)
    const [viewOver, setViewOver]=useState(false)
    const [contenuOver, setContenuOver]=useState({})
    const [selection, setSelection] =useState([])
    const [menuAction, setMenuAction] = useState()
    const [filtres, setFiltres] = useState()
    const [ordre, setOrdre] = useState()
    const [initSelection,setInitSelection] = useState(false)
    const [listeAff, setListeAff] = useState([])
    const [rech, setRech] = useState(false)
    const [validations, setValidations] = useState()
    const [statutValidation, setStatutValidation] = useState()
    const [refs, setRefs] = useState([])
    const [refsAff, setRefsAff] = useState([])

    useEffect(() => {
      //
    }, [page])

    useEffect(() => {
      //console.log(listeAff)
    }, [listeAff])
    
    function onActionChange(newAction,id=0) {
        setAction(newAction)
        //console.log("Action (reçue dans Layout) : " +newAction)
        if(newAction==="viewSearch") {
          setViewOver(true)
          setContenuOver({cont:"search"})
        }
        if(newAction==="viewRef") {
          setViewOver(true)
          setContenuOver({cont:"viewRef",id:id})
        }
        if(newAction==="viewRefLocal") {
          setViewOver(true)
          setContenuOver({cont:"viewRefLocal",id:id})
        }
        if(newAction==="editRef") {
          setViewOver(true)
          setContenuOver({cont:"editRef",id:id})
        }
        if(newAction==="select-false") {
          setInitSelection(true)
        }
        if(newAction==="select-true") {
          setInitSelection(false)
        }
    }

    function onListChange(refsAff){
      let tempArray=[]
      refsAff.forEach(ref => {
        tempArray.push(ref.id)
      });
      setListeAff(tempArray)
    }

    function getSelection(liste){
      setSelection(liste)
    }

    function getMenuAction(menuAction){
      setMenuAction(menuAction)
    }

    function getFiltres(filtres){
      //console.log(filtres)
      setFiltres(filtres)
    }

    function getOrdre(ordre){
      //console.log(ordre)
      setOrdre(ordre)
    }

    const isOnline = useNavigatorOnLine();
    return(
        <div className='layout'>
            <Over 
              action={action}
              actionChange={onActionChange} 
              listeAff={listeAff}
              viewOver={viewOver} 
              setViewOver={setViewOver} 
              contenu={contenuOver} 
              page={page}
              getFiltres={getFiltres}
              setRech={setRech}
              isOnline={isOnline}
              validations={validations}
              setValidations={setValidations}
              statutValidation={statutValidation}
              setStatutValidation={setStatutValidation}
              refs={refs}
              setRefs={setRefs}
              refsAff={refsAff}
              setRefsAff={setRefsAff}
            />
            <Menu2 
              actionChange={onActionChange} 
              selection={selection} 
              getMenuAction={getMenuAction} 
              getFiltres={getFiltres} 
              getOrdre={getOrdre} 
              page={page} 
              setRech={setRech}
              isOnline={isOnline}
            />
            <Content
              action={action}
              actionChange={onActionChange}
              onListChange={onListChange}
              getSelection={getSelection} 
              initSelection={initSelection} 
              menuAction={menuAction} 
              setMenuAction={setMenuAction} 
              filtres={filtres} 
              ordre={ordre}
              page={page}
              refId={refId}
              viewOver={viewOver}
              rech={rech}
              isOnline={isOnline}
              validations={validations}
              setValidations={setValidations}
              statutValidation={statutValidation}
              setStatutValidation={setStatutValidation}
              refs={refs}
              setRefs={setRefs}
              refsAff={refsAff}
              setRefsAff={setRefsAff}
              />
            <Menu1 
              page={page}
              isOnline={isOnline} 
            />
        </div>
    )
}
export default Layout